import { defineMessages, type MessageDescriptor } from 'react-intl-next';
import { type ApolloError as ApolloErrorType, ApolloError } from 'apollo-client';

import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';
import { Attribution, isUnauthorizedError } from '@confluence/error-boundary';
import { getMonitoringClient, setContentErrorAttributes } from '@confluence/monitoring';
import { containsNoNetworkError } from '@confluence/network';
import { markErrorAsHandled } from '@confluence/graphql';

const i18nBase = defineMessages({
	unknownError: {
		id: 'live-pages-features.converttolivepage.modal.error.unknown',
		defaultMessage: 'An error occurred. Please try again.',
		description: 'Dialog content body when error happens in convert to live pages dialog',
	},
	serverException: {
		id: 'live-pages-features.converttolivepage.modal.error.serverException',
		defaultMessage: 'An unexpected error occurred. Please try again later.',
		description:
			'Displayed when an unexpected server-side error prevents the page from being converted to live edit mode.',
	},
	pageNotFound: {
		id: 'live-pages-features.converttolivepage.modal.error.pageNotFound',
		// TODO: Fix the direction of the curly quote below. (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: 'The page you’re trying to edit doesn‘t exist.',
		description:
			'Shown when the user attempts to convert a page to live page, but the specified page ID does not exist or cannot be found.',
	},
	contentServiceException: {
		id: 'live-pages-features.converttolivepage.modal.error.contentServiceException',
		defaultMessage: 'The requested content is not available.',
		description:
			'Appears when the content requested for conversion to a live page cannot be retrieved, possibly due to access restrictions or deletion.',
	},
	pageAlreadyLive: {
		id: 'live-pages-features.converttolivepage.modal.error.pageAlreadyLive',
		defaultMessage: 'This page has already been switched to a live page.',
		description:
			'Informs the user that the page they are attempting to convert is already a live page and does not need conversion.',
	},
	legacyPageConversionError: {
		id: 'live-pages-features.converttolivepage.modal.error.legacyPageConversionError',
		defaultMessage: 'This page can’t be switched to a live page due to its outdated format.',
		description:
			'Displayed when a page uses a legacy format that is not compatible with the live pages feature.',
	},
	noEditPermissions: {
		id: 'live-pages-features.converttolivepage.modal.error.noEditPermissions',
		defaultMessage: 'You don’t have the necessary permissions to switch this to a live page.',
		description:
			'Displayed when the user lacks the edit permissions required to convert a page to a live page.',
	},
	draftTitleConflict: {
		id: 'live-pages-features.converttolivepage.draft-conversion-title-conflict.description',
		defaultMessage:
			'There’s already content named “{pageTitle}” in this space. Give this page a unique title.',
		description:
			'error description shown to user when their page has the same title as other content, preventing them from converting it',
	},
	viewScreenTitleConflict: {
		id: 'live-pages-features.converttolivepage.modal.error.view-screen-conversion-title-conflict',
		defaultMessage:
			'There’s already content with this page’s updated title in the space. Give this page a unique title.',
		description:
			'Error message shown when there is other content in the same space with the same title.  This variant is shown when on the view screen when the conflicting draft title is not known.',
	},
});

const i18nLiveDocs = defineMessages({
	pageAlreadyLive: {
		id: 'live-pages-features.converttolivepage.modal.error.pageAlreadyLive.livedocs',
		defaultMessage: 'This page has already been converted to a live doc.',
		description:
			'Updated version of live-pages-features.converttolivepage.modal.error.pageAlreadyLive',
	},

	legacyPageConversionError: {
		id: 'live-pages-features.converttolivepage.modal.error.legacyPageConversionError.livedocs',
		defaultMessage: 'This page can’t be converted to a live doc due to its outdated format.',
		description:
			'Updated version of live-pages-features.converttolivepage.modal.error.legacyPageConversionError',
	},

	noEditPermissions: {
		id: 'live-pages-features.converttolivepage.modal.error.noEditPermissions.livedocs',
		defaultMessage: 'You don’t have the necessary permissions to convert this to a live doc.',
		description:
			'Updated version of live-pages-features.converttolivepage.modal.error.noEditPermissions',
	},

	draftTitleConflict: {
		id: 'live-pages-features.converttolivepage.draft-conversion-title-conflict.description.livedocs',
		defaultMessage:
			'There’s already content named “{pageTitle}” in this space. Give this page a unique title.',
		description:
			'Updated version of live-pages-features.converttolivepage.draft-conversion-title-conflict.description',
	},
});

export const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

const BACKEND_ERROR_MESSAGES_BY_KEY: Partial<Record<keyof typeof i18n, string>> = {
	serverException: 'Some server exception',
	pageNotFound: 'Page not found with provided id',
	contentServiceException: 'Content service exception',
	pageAlreadyLive: 'Page subtype is already set to live',
	legacyPageConversionError: 'Legacy page cannot be converted to live edit',
	noEditPermissions: 'Page cannot be converted without edit permissions',
	draftTitleConflict: 'Title already exists',
};
const BACKEND_ERROR_KEYS_BY_MESSAGE: Record<string, Partial<keyof typeof i18n>> = Object.entries(
	BACKEND_ERROR_MESSAGES_BY_KEY,
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});
const HANDLED_BACKEND_ERROR_MESSAGES = [
	BACKEND_ERROR_MESSAGES_BY_KEY.draftTitleConflict,
	BACKEND_ERROR_MESSAGES_BY_KEY.pageAlreadyLive,
	BACKEND_ERROR_MESSAGES_BY_KEY.noEditPermissions,
	BACKEND_ERROR_MESSAGES_BY_KEY.pageNotFound,
];

export const getI18nStringForError = (errorMessage: string): MessageDescriptor => {
	return i18n[BACKEND_ERROR_KEYS_BY_MESSAGE[errorMessage]] || i18n.unknownError;
};

export const submitErrorToMonitoringSystem = (error: ApolloError | Error, contentId: string) => {
	setContentErrorAttributes(error, {
		objectId: contentId,
	});

	getMonitoringClient().submitError(error, {
		attribution: Attribution.PAGE_MODES,
	});
};

export const processError = (
	error: ApolloErrorType | Error | { message: string | null; __typename?: string },
	contentId: string,
): { isHandled: boolean } => {
	let normalizedError: Error | ApolloError;
	if (error instanceof Error || error instanceof ApolloError) {
		normalizedError = error;
	} else {
		// NOTE: Mutation errors from conversion have a null prototype, causing some below checks to throw an error because `hasOwnProperty` doesn't exist.
		//       Wrapping in a new Error retains original context and ensures the error contains `hasOwnProperty`, so we can handle the errors we're passed without issue.
		normalizedError = new Error(error?.message || 'Unknown error');
		if (error?.__typename) normalizedError.name = error?.__typename;
	}
	const isHandledConversionError = HANDLED_BACKEND_ERROR_MESSAGES.includes(normalizedError.message);

	if (
		isUnauthorizedError(normalizedError) ||
		containsNoNetworkError(normalizedError) ||
		isHandledConversionError
	) {
		markErrorAsHandled(normalizedError);
		return { isHandled: true };
	} else {
		submitErrorToMonitoringSystem(normalizedError, contentId);
		return { isHandled: false };
	}
};
