import type { ComponentProps } from 'react';
import React, { useCallback, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConvertToFolderSpotlight } from '@confluence/folder-onboarding';
import type { FormattedWebItem } from '@confluence/web-item-location';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import type { DialogsStateContainer } from '@confluence/dialogs';
import { ConvertPageToFolderDialog } from '@confluence/folder-conversion/entry-points/ConvertPageToFolderDialog';
import { useShowConvertPageToFolder } from '@confluence/folder-conversion/entry-points/useShowConvertPageToFolder';
import { fg } from '@confluence/feature-gating';
import { ErrorDisplay } from '@confluence/error-boundary';

import { LazyContentToolsItem } from './LazyContentToolsItem';

export const ConvertToFolderItem = ({
	sectionItem,
	contentId,
	spaceKey,
	dialogs,
	shouldHighlight,
	onClick,
	...props
}: ConvertToFolderItemProps) => {
	const [isSpotlightOpen, setIsSpotlightOpen] = useState(true);

	const { canConvertPageToFolder, isPageFolderConversionCandidate, contentTitle, error } =
		useShowConvertPageToFolder({
			contentId,
			spaceKey,
			skipQueryIfBannerDisabledOrDismissed: false,
		});

	const [isConvertToFolderOnboardingActive, stopConvertToFolderOnboarding] =
		useSkippableCoordination(
			'cc-convert-to-folder-onboarding',
			!fg('confluence_frontend_convert_to_folder_onboarding') ||
				!isPageFolderConversionCandidate ||
				Boolean(error),
			false,
		);

	const handleSpotlightClose = useCallback(() => {
		setIsSpotlightOpen(false);
		void stopConvertToFolderOnboarding();
	}, [stopConvertToFolderOnboarding]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const openDialog = (e: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => {
		onClick?.(e);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'convertPageToFolder',
				source: 'contentToolsItem',
			},
		}).fire();
		dialogs.showDialog(ConvertPageToFolderDialog, {
			contentId,
			spaceKey,
			contentTitle,
		});
	};

	if (!canConvertPageToFolder) {
		return error ? <ErrorDisplay error={error} /> : null;
	}

	sectionItem.url = '';
	sectionItem.urlWithoutContextPath = '';

	const shouldShowOnboarding =
		isConvertToFolderOnboardingActive &&
		isSpotlightOpen &&
		fg('confluence_frontend_convert_to_folder_onboarding');

	const convertToFolderItem = (
		<>
			{error && <ErrorDisplay error={error} />}
			<LazyContentToolsItem
				key={sectionItem.completeKey}
				{...sectionItem}
				{...props}
				onClick={openDialog}
				// already highlighted when showing onboarding
				shouldHighlight={!shouldShowOnboarding && shouldHighlight}
			/>
		</>
	);

	if (shouldShowOnboarding) {
		return (
			<ConvertToFolderSpotlight onClose={handleSpotlightClose}>
				{convertToFolderItem}
			</ConvertToFolderSpotlight>
		);
	}

	return convertToFolderItem;
};

type ConvertToFolderItemProps = {
	sectionItem: FormattedWebItem;
	contentId: string;
	spaceKey: string;
	dialogs: DialogsStateContainer;
	shouldHighlight?: boolean;
} & Partial<ComponentProps<typeof LazyContentToolsItem>>;
