import { styled } from '@compiled/react';
import React, { Component } from 'react';

import { token } from '@atlaskit/tokens';

import { requireSuperBatch } from '@confluence/wrm';

import type { FormattedWebItem, WebItemLocationChildrenFn } from './WebItemLocation';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.aui_buttons': {
		display: 'inline-block',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.aui-button.aui-button-subtle': {
		boxSizing: 'border-box',
		borderRadius: '3.01px',
		border: '1px solid transparent',
		display: 'inline-block',
		font: token('font.heading.xsmall'),
		fontFamily: 'inherit',
		height: '2.14285714em',
		padding: `${token('space.050')} 10px`,
		textDecoration: 'none',
		verticalAlign: 'baseline',
		whiteSpace: 'nowrap',
	},
});

export type WebItemLocationComponentProps = {
	/**
	 * Tag name for the container
	 */
	tagName?: string;
	/**
	 * class name for the container
	 */
	className?: string;
	/**
	 * id for the container
	 */
	id?: string;
	/**
	 * Context content id
	 */
	contentId?: string;
	/**
	 * Context content id
	 */
	waitForSuperBatch?: boolean;
	/**
	 * styles for the container
	 */
	style?: object;
	/**
	 * A function that will receive the webitem list and render it
	 */
	children?: WebItemLocationChildrenFn;
	/**
	 * List of webitems
	 */
	webItems: FormattedWebItem[];
	/**
	 * Ordered list of websections
	 */
	webSections: any[];
	/**
	 * Web location in "location/section" format
	 */
	location: string | string[];
	/**
	 * Callback to run after the web items are rendered. In Confluence, some webitems require scripts to run. This
	 * callback give you the chance to run them.
	 */
	onLoad?: Function;
};

export type WrapperProps = {
	'data-webitem-location': string | string[];
	'data-content-id': string | undefined;
	children: React.ReactNode;
	id?: string;
	className?: string;
	style?: object;
};

export class WebItemLocationComponent extends Component<WebItemLocationComponentProps> {
	static defaultProps = {
		tagName: 'div',
	};

	state = {
		superBatchLoaded: false,
	};

	componentDidMount() {
		this.mounted = true;
		const { webItems, onLoad, waitForSuperBatch } = this.props;

		if (onLoad) {
			onLoad({ webItems });
		}

		if (waitForSuperBatch) {
			void requireSuperBatch().then(() => {
				if (this.mounted) {
					this.setState({ superBatchLoaded: true });
				}
			});
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	private mounted: boolean = false;

	render() {
		const {
			id,
			tagName,
			className,
			style,
			children,
			webItems,
			webSections,
			location,
			contentId,
			waitForSuperBatch,
		} = this.props;

		if (waitForSuperBatch && !this.state.superBatchLoaded) {
			return null;
		}

		const wrapperProps: WrapperProps = {
			'data-webitem-location': location,
			'data-content-id': contentId,
			children: typeof children === 'function' ? children({ webItems, webSections }) : null,
		};

		if (id) {
			wrapperProps.id = id;
		}
		if (className) {
			wrapperProps.className = className;
		}
		if (style) {
			wrapperProps.style = style;
		}
		// Addon icons are broken during SSR, this is because we do not SSR legacy styles.
		// In order for styles to match during SSR and SPA for addon icons, we need to
		// declare a styled component such as emotion/styled. This way we can pass css to their children via wrapper.
		// Until superbatch is loaded, the styles for addon icons would still be broken.
		if ((wrapperProps?.children as React.ReactElement)?.props?.buttonType === 'aui-button-subtle') {
			return <StyledWrapper>{React.createElement(tagName!, wrapperProps)}</StyledWrapper>;
		}

		return React.createElement(tagName!, wrapperProps);
	}
}
