import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';
import type { FlagsStateContainer } from '@confluence/flags';
import { VIEW_PAGE } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { usePageSpaceKey, usePageContentId } from '@confluence/page-context';

const i18nBase = defineMessages({
	flagTitle: {
		id: 'live-pages-features.convert-to-live-page-flag.flagTitle',
		description:
			'Title for the flag that informs the user that the page is converted to a live page',
		defaultMessage: 'This page was switched to a live page',
	},
	switcherFlagTitle: {
		id: 'live-pages-features.convert-to-live-page-flag.switcherFlagTitle',
		description:
			'Title for the flag that informs the user that converted the page to live page that the option is success',
		defaultMessage: 'You’ve switched to a live page',
	},
	flagBody: {
		id: 'live-pages-features.convert-to-live-page-flag.flagBody',
		description:
			'Body for the flag that informs the user that the page is converted to a live page',
		defaultMessage: 'Updates to this page will now be instant and visible in real time.',
	},
	findOutMore: {
		id: 'live-pages-features.convert-to-live-page-flag.findOutMore',
		description:
			'Link on the convert to live edit flag direct a user to more information about live pages',
		defaultMessage: 'Find out more',
	},
	reloadButton: {
		id: 'live-pages-features.convert-to-live-page-flag.reloadButton',
		description: 'Button that causes a page reload and redirect the user to live page',
		defaultMessage: 'Reload',
	},
});

const i18nLiveDocs = defineMessages({
	flagTitle: {
		id: 'live-pages-features.convert-to-live-page-flag.flagTitle.livedocs',
		defaultMessage: 'This page was converted to a live doc',
		description: 'Title for flag that displays after someone else converts a page to a live doc',
	},
	switcherFlagTitle: {
		id: 'live-pages-features.convert-to-live-page-flag.switcherFlagTitle.livedocs',
		defaultMessage: 'You’ve converted to a live doc',
		description: 'Title for flag that displays after you convert a page to a live doc',
	},
	flagBody: {
		id: 'live-pages-features.convert-to-live-page-flag.flagBody.livedocs',
		defaultMessage: 'Updates to this doc will now be instant and visible in real time.',
		description:
			'Description for flag that displays after a page is converted to a live doc.  It explains that updates to live docs are visible right away for anyone viewing them.',
	},
	findOutMore: {
		id: 'live-pages-features.convert-to-live-page-flag.findOutMore.livedocs',
		defaultMessage: 'Find out more about live docs',
		description:
			'Link on the convert to live doc flag that directs a user to more information about live docs',
	},
});

export const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

const LEARN_MORE_URL =
	'https://support.atlassian.com/confluence-cloud/docs/create-and-collaborate-in-real-time-with-live-docs/';

const CONVERT_SUCCESS_FLAG_AUTO_CLOSE_TIME = 11000;

type Props = {
	flags: FlagsStateContainer;
};
type ShowConvertFlagFunction = ({
	converterWasCurrentUser,
}: {
	converterWasCurrentUser: boolean;
}) => void;

export const useShowConvertToLivePageFlag = ({ flags }: Props): ShowConvertFlagFunction => {
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const intl = useIntl();
	const { replace } = useRouteActions();
	const isOnEditRoute = useIsEditorPage();

	const handleOnClick = useCallback(() => {
		const livePageLink = VIEW_PAGE.toUrl({
			spaceKey,
			contentId,
		});
		return isOnEditRoute ? replace(livePageLink) : window.location.reload();
	}, [isOnEditRoute, replace, spaceKey, contentId]);

	return useCallback(
		async ({ converterWasCurrentUser }: { converterWasCurrentUser: boolean }) => {
			const successFlag = await flags.showFlag({
				title: converterWasCurrentUser
					? intl.formatMessage(i18n.switcherFlagTitle)
					: intl.formatMessage(i18n.flagTitle),
				description: intl.formatMessage(i18n.flagBody),
				type: 'success',
				close: 'never', // Needed to bypass the flags.hideAllFlags() call in EditorFlags.tsx on editor unmount.
				actions: [
					{
						content: <div data-testid="reload-button">{intl.formatMessage(i18n.reloadButton)}</div>,
						onClick: handleOnClick,
					},
					{
						content: (
							<div data-testid="find-out-more-button">{intl.formatMessage(i18n.findOutMore)}</div>
						),
						onClick: () => window.open(LEARN_MORE_URL, '_blank'),
					},
				],
			});
			// Manually set a timeout so the flag auto-closes after the page transition completes.
			setTimeout(() => flags.hideFlag(successFlag.id), CONVERT_SUCCESS_FLAG_AUTO_CLOSE_TIME);
		},
		[intl, flags, handleOnClick],
	);
};
