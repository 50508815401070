import { LoadableAfterPaint } from '@confluence/loadable';
export { PublishPageAsBlogSources } from './PublishPageAsBlogSourcesEnum';

export const PublishPageAsBlogDialog = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PublishPageAsBlogDialog" */ './PublishPageAsBlogDialog'
			)
		).PublishPageAsBlogDialog,
});
