import { LoadableLazy } from '@confluence/loadable';

export const ConvertToLivePageDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ConvertToLivePageDialogLoadable" */ './ConvertToLivePageDialog'
			)
		).ConvertToLivePageDialog,
});
