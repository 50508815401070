import React, { Component } from 'react';
import { Query } from 'react-apollo';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import type { WatchQueryFetchPolicy } from 'apollo-client';

import { ErrorDisplay, isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';

import { WebItemLocationComponent } from './WebItemLocationComponent';
import { WebItemLocationQuery } from './WebItemLocationQuery.graphql';
import type {
	WebItemLocationQuery as WebItemLocationQueryType,
	WebItemLocationQueryVariables,
	WebItemLocationQuery_webItemSections_items,
} from './__types__/WebItemLocationQuery';
import { replaceAtlTokenPlaceholder } from './replaceAtlTokenPlaceholder';
import type { WrapperProps } from './WebItemLocationComponent';
import { filterConnectAppsFromExtensionPoints } from './webItemUtils';

export type FormattedWebItem = Omit<WebItemLocationQuery_webItemSections_items, 'params'> & {
	parentSection: { label: string | null };
	webSection: string;
	contentId?: string;
	params: Record<string, string>;
};

export type WebItemLocationChildrenFn = (args: {
	webItems: FormattedWebItem[];
	webSections: string[];
}) => React.ReactNode;

export type WebItemLocationChildrenFnWithLoading = (args: {
	webItems: FormattedWebItem[];
	webSections: string[];
	loading?: boolean;
}) => React.ReactNode;

export type WebItemLocationProps = {
	location: string | string[];
	children?: WebItemLocationChildrenFn | WebItemLocationChildrenFnWithLoading;
	contentId?: string;
	spaceKey?: string;
	version?: number;
	fetchPolicy?: WatchQueryFetchPolicy;
	waitForSuperBatch?: boolean;
	id?: string;
	className?: string;
	tagName?: string;
	onLoad?: Function;
	hasMultipleSections?: boolean;
	allowedWebItems?: any[];
	notAllowedWebItems?: any[];
	style?: Object;
	experienceSuccess?(result: boolean): void;
	renderWhenLoading?: boolean;
} & (
	| { renderWhenLoading: true; children?: WebItemLocationChildrenFnWithLoading }
	| { renderWhenLoading?: false; children?: WebItemLocationChildrenFn }
);
type AdditionalLivePagesProps = {
	isInLivePagesOpenBeta: boolean;
};

export class WebItemLocationImpl extends Component<
	WebItemLocationProps & AdditionalLivePagesProps
> {
	experienceSuccess = (result: boolean) => {
		if (this.props.experienceSuccess) {
			this.props.experienceSuccess(result);
		}
	};

	render() {
		const {
			location,
			children,
			onLoad,
			hasMultipleSections,
			renderWhenLoading,
			allowedWebItems,
			notAllowedWebItems,
			fetchPolicy = 'cache-and-network',
			spaceKey,
			contentId,
			id,
			className,
			style,
			tagName,
			version,
			waitForSuperBatch,
			isInLivePagesOpenBeta,
		} = this.props;

		const variables: WebItemLocationQueryVariables = {
			// GraphQL doesn't cache single location strings properly when passed to locations
			// unless we're using multiple locations we should be using the location variable
			location: Array.isArray(location) ? null : location,
			locations: Array.isArray(location) ? location : [],
			contentId: contentId || null,
			spaceKey: spaceKey || null,
			version: version || null,
		};

		return (
			<Query<WebItemLocationQueryType, WebItemLocationQueryVariables>
				query={WebItemLocationQuery}
				variables={variables}
				fetchPolicy={fetchPolicy}
			>
				{({ data, loading, error }) => {
					// Since the default fetchPolicy is cache-and-network
					// loading field will be true, false, true (background refetch), false
					// Thus destroying and rebuilding the children
					// Here we only block rendering on the very first loading.
					if (loading && !data) {
						if (renderWhenLoading && typeof children === 'function') {
							// Render wrapper to keep DOM structure consistent while loading
							const wrapperProps: WrapperProps = {
								'data-webitem-location': location,
								'data-content-id': contentId,
								children: children({
									loading,
									webItems: [],
									webSections: [],
								}),
							};
							if (id) {
								wrapperProps.id = id;
							}
							if (className) {
								wrapperProps.className = className;
							}
							if (style) {
								wrapperProps.style = style;
							}
							const wrapperTag = tagName || 'div';
							return React.createElement(wrapperTag, wrapperProps);
						} else {
							return null;
						}
					}

					if (error) {
						if (isUnauthorizedError(error)) {
							markErrorAsHandled(error);
							return null;
						}
						// TODO: unknown errors should have a nicer UI within `<ErrorDisplay>`, but we don't have such UI for now
						// so returning empty `<ErrorDisplay>` here.
						return <ErrorDisplay error={error} />;
					}

					type UnformattedWebItem = Omit<FormattedWebItem, 'params' | 'webSection'> & {
						params: WebItemLocationQuery_webItemSections_items['params'];
					};

					// replace any instances of {atlTokenPlaceholderWillBeReplacedInFrontend}
					// with the atlToken in all section items
					let webItems = (replaceAtlTokenPlaceholder(data!)?.webItemSections ?? []).reduce<
						UnformattedWebItem[]
					>((items, section) => {
						return items.concat(
							(section!.items || []).map((item) => ({
								...item!,
								parentSection: {
									label: section!.label || section!.styleClass,
								},
							})),
						);
					}, []);

					webItems = uniqBy(webItems, 'completeKey');

					if (allowedWebItems) {
						webItems = webItems.filter((webItem) => allowedWebItems.includes(webItem.completeKey));
					}

					if (notAllowedWebItems) {
						webItems = webItems.filter(
							(webItem) => !notAllowedWebItems.includes(webItem.completeKey),
						);
					}

					if (isInLivePagesOpenBeta) {
						webItems = filterConnectAppsFromExtensionPoints(location, webItems);
					}

					webItems.forEach((item) => {
						const paramObj: Record<string, string> = {};
						if (Array.isArray(item.params)) {
							item.params.forEach((param) => {
								paramObj[param!.key!] = param!.value!;
							});
							(item as any as FormattedWebItem).params = paramObj;
						}
						if (item.section && item.section.indexOf('/') !== -1) {
							const parts = item.section.split('/');
							(item as any as FormattedWebItem).webSection = parts[1];
						}
					});
					const formattedWebItems: FormattedWebItem[] = webItems as any;

					let webSections: string[] = [];
					if (hasMultipleSections) {
						webSections = uniq(formattedWebItems.map((webItem) => webItem.section!));
					}

					formattedWebItems.sort((webItemA, webItemB) => {
						if (webItemA.weight! - webItemB.weight! === 0) {
							return webItemA.label!.localeCompare(webItemB.label!);
						} else {
							return webItemA.weight! - webItemB.weight!;
						}
					});

					if (!loading) {
						this.experienceSuccess(true);
					}
					return (
						<WebItemLocationComponent
							id={id}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={className}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={style}
							tagName={tagName}
							location={location}
							webItems={formattedWebItems}
							webSections={webSections}
							onLoad={onLoad}
							waitForSuperBatch={waitForSuperBatch}
						>
							{children}
						</WebItemLocationComponent>
					);
				}}
			</Query>
		);
	}
}

export const WebItemLocation = (props: WebItemLocationProps) => {
	const { isTwoPageTypesExperience } = useIsLivePagesFeatureEnabled();

	return <WebItemLocationImpl {...props} isInLivePagesOpenBeta={isTwoPageTypesExperience} />;
};
