import { LoadableLazy } from '@confluence/loadable';

export { BULK_DELETE_PAGE_SELECTION_LIMIT_DEFAULT } from './constants';

export type { BulkDeletePagesActionProps } from './Delete/BulkDeletePagesAction';

export { getBulkConvertToLiveDocPageLimit } from './ConvertPageToLiveDoc/getBulkConvertToLiveDocPageLimit';

import { fg } from '@confluence/feature-gating';

export const DeleteNestedPagesActionLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DeleteNestedPagesActionLoader" */ './Delete/DeleteNestedPagesAction'
			)
		).DeleteNestedPagesAction,
});

export const BulkDeletePagesActionLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkDeletePagesActionLoader" */ './Delete/BulkDeletePagesAction'
			)
		).BulkDeletePagesAction,
});

export const BulkChangeOwnerActionLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkChangeOwnerActionLoader" */ './ChangeOwner/BulkChangeOwnerAction'
			)
		).BulkChangeOwnerAction,
});

export const BulkChangeStatusActionLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkChangeStatusActionLoader" */ './ChangeStatus/BulkChangeStatusAction'
			)
		).BulkChangeStatusAction,
});

export const NestedChangeOwnerActionLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-NestedChangeOwnerActionLoader" */ './ChangeOwner/NestedChangeOwnerAction'
			)
		).NestedChangeOwnerAction,
});

export const BulkConvertPageToLiveDocActionLoader = LoadableLazy({
	loader: async () => {
		if (fg('a4c-3732-live-doc-conversion-with-bulk-mutation')) {
			return (
				await import(
					/* webpackChunkName: "loadable-BulkConvertPageToLiveDocActionLoader" */ './ConvertPageToLiveDoc/NestedConvertPageToLiveDocAction'
				)
			).NestedConvertPageToLiveDocAction;
		} else {
			return (
				await import(
					/* webpackChunkName: "loadable-BulkConvertPageToLiveDocActionLoader" */ './ConvertPageToLiveDoc/BulkConvertPageToLiveDocAction'
				)
			).BulkConvertPageToLiveDocAction;
		}
	},
});
