import gql from 'graphql-tag';

export const convertPageToLiveEditMutation = gql`
	mutation ConvertPageToLiveEdit($contentId: ID!) {
		convertPageToLiveEditAction(input: { contentId: $contentId }) {
			success
			errors {
				message
			}
		}
	}
`;
