import { AccessStatus, useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { expValEquals, expValEqualsNoExposure } from '@confluence/feature-experiments';

export const useUnifiedShareDialogEligible = (skipExposure: boolean = false) => {
	const { edition, isLoggedIn, accessStatus } = useSessionData();

	const isLicensed = [
		AccessStatus.LICENSED_ADMIN_ACCESS,
		AccessStatus.LICENSED_USE_ACCESS,
	].includes(accessStatus as AccessStatus);

	const isStandard = [ConfluenceEdition.STANDARD].includes(edition as ConfluenceEdition);

	const isEligible = isLoggedIn && isLicensed && isStandard;

	return {
		isUSDExperimentEnabled: isEligible
			? skipExposure
				? expValEqualsNoExposure('unified_share_dialog_usd_experiment', 'cohort', 'test')
				: expValEquals('unified_share_dialog_usd_experiment', 'cohort', 'test')
			: false,
	};
};
