import React from 'react';

import { LoadableLazy } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';

import { ContentToolsItemPlaceholder } from './ContentToolsItemPlaceholder';

export const LazyContentToolsItem = LoadableLazy({
	loader: async () =>
		fg('confluence_link_item_append_trace_context')
			? (
					await import(
						/* webpackChunkName: "loadable-ContentToolsItemWithRoutesContext" */ './ContentToolsItemWithRoutesContext'
					)
				).ContentToolsItemWithRoutesContext
			: (await import(/* webpackChunkName: "loadable-ContentToolsItem" */ './ContentToolsItem'))
					.ContentToolsItem,
});

export const LazyContentToolsItemWithPlaceholder = LoadableLazy({
	loader: async () =>
		fg('confluence_link_item_append_trace_context')
			? (
					await import(
						/* webpackChunkName: "loadable-ContentToolsItemWithRoutesContext" */ './ContentToolsItemWithRoutesContext'
					)
				).ContentToolsItemWithRoutesContext
			: (await import(/* webpackChunkName: "loadable-ContentToolsItem" */ './ContentToolsItem'))
					.ContentToolsItem,
	loading: () => <ContentToolsItemPlaceholder />,
});
