export const EDITOR_ONBOARDING_STATE_KEYS = {
	EDITOR_ONBOARDING_ELIGIBLE_NEW: 'editor-state:editorOnboardingEligiblity', // we made a typo :(
	EDITOR_VISITED: 'editor-state:editorVisited',
	WAS_DRAFT_BANNER_SEEN: 'editor-state:wasDraftBannerSeen',
	IS_DRAFT_BANNER_VISIBLE: 'editor-state:isDraftBannerVisible',
	IS_LAND_IN_EDITOR_BANNER_VISIBLE: 'editor-state:isLandInEditorBannerVisible',
	HAS_USER_PUBLISHED: 'editor-state:hasUserPublished',
	SUCCESS_FLAG_SEEN: 'editor-state:successFlagCompleted',
	RECENT_SPOTLIGHT_OPEN: 'editor-state:recentSpotlightOpen',
	RECENT_SPOTLIGHT_SEEN: 'editor-state:recentSpotlightCompleted',
	EDITOR_TUTORIAL_SUCCESS_FLAG_SEEN: 'editor-state:editorTutorialSuccessFlagCompleted',
	WAS_EDITOR_CONVERSION_DIALOG_SEEN: 'editor-state:wasEditorConversionModalSeen',
};

export const EDITOR_ONBOARDING_BLUEPRINT_KEY =
	'com.atlassian.confluence.plugins.confluence-onboarding:using-the-editor-template-blueprint';

export const EDITOR_ONBOARDING_BLUEPRINT_KEY_LIVE_DOCS_ENABLED =
	'com.atlassian.confluence.plugins.confluence-onboarding:using-the-editor-template-v2-blueprint';

export const ONBOARDING_TUTORIAL_LABEL = 'editor-onboarding';

export type OnboardingEditorState = {
	editorOnboardingEligiblity?: boolean;
	editorVisited?: boolean;
	wasDraftBannerSeen?: boolean;
	isDraftBannerVisible?: boolean;
	hasUserPublished?: boolean;
	successFlagCompleted?: boolean;
	recentSpotlightOpen?: boolean;
	recentSpotlightCompleted?: boolean;
	wasEditorConversionModalSeen?: boolean;
	isLandInEditorBannerVisible?: boolean;
};
