import { query } from '@confluence/query-preloader-tools';
import type { FetchPolicy } from 'apollo-client';

import { TitleContentPropertyQuery, HomepageIdQuery } from './TitleContentPropertyQuery.graphql';
import type {
	HomepageIdQuery as HomepageIdQueryType,
	HomepageIdQueryVariables,
} from './__types__/HomepageIdQuery';

const preloadHomepageId = (spaceKey: string) =>
	query<HomepageIdQueryType, HomepageIdQueryVariables>({
		query: HomepageIdQuery,
		variables: {
			spaceKey,
		},
	});

const preloadTitleContentProperty = (contentId: string, fetchPolicy: FetchPolicy = 'cache-first') =>
	query({
		query: TitleContentPropertyQuery,
		variables: {
			contentId,
			status: ['current', 'draft', 'archived'],
		},
		fetchPolicy: fetchPolicy,
	});

export const preloadPageTitleContentProperties = async ({
	contentId,
	spaceKey,
	fetchPolicy = 'cache-first',
}: {
	contentId: string;
	spaceKey: string;
	fetchPolicy?: FetchPolicy;
}) => {
	const promises: Promise<any>[] = [];
	if (!contentId) {
		promises.push(
			preloadHomepageId(spaceKey).then((res) => {
				const homepageId = res?.data?.space?.homepage?.id;
				if (homepageId) {
					return preloadTitleContentProperty(homepageId);
				}
			}),
		);
	} else {
		promises.push(preloadTitleContentProperty(contentId, fetchPolicy));
	}

	return Promise.all(promises);
};
